<template>

	<form @submit.prevent="onSubmit" novalidate>

		<mdb-container>

			<mdb-card class="mt-5">

				<span class="bg-primary pl-4 p-3 text-white strong rounded-top">
					<i class="fa fa-pencil-alt fa-sm mr-2"></i> Edit Asset
				</span>

				<mdb-card-body>

					<fieldset>

						<template v-for="(fieldData, fieldName) in getForm('addAsset')">

							<form-field
								v-bind="checkForOptions(fieldName, fieldData)"
								v-model="vModels[fieldName]"
								:name="fieldName"
								:label="getLabel(fieldName)"
								:error="errors[fieldName]"
								:locale="locale"
								:mask="getMask(fieldName, fieldData)"
								:enable-only="enableOnly[fieldName]"
								:options="fieldName == 'assetTypes' ? assetTypes : assetSubTypes"
								bootstrap
								@change="onChange"
							></form-field>


						</template>

					</fieldset>

				</mdb-card-body>
			</mdb-card>

			<div class="form-group button-row">

				<submit-btn
					type="button"
					color="primary"
					:loading="goingBack"
					key="back-btn"
					@click="goBack({name: 'Assets', params: $route.params})"
				>{{ $t('assets.cancel') }}</submit-btn>

				<submit-btn
					type="submit"
					:loading="goingBack"
					@click="saveBtn()"
				>{{ $t('assets.save') }}</submit-btn>

			</div>

		</mdb-container>

	</form>

</template>

<script>

	import ApplicationMixin from '../ApplicationForms/Application/ApplicationMixin';
	import { getAssetTypes, getAssetSubTypes, getAssets, addAsset } from '../../../api';

	export default {

		mixins: [
			ApplicationMixin
		],

		data() {

			return {
				assetTypes: {},
				assetSubTypes: {},
				submitting: false,
				goingBack: false,

				vModels: {
					assetTypes: null,
					assetSubTypes: null,
					description: '',
					retailCost: '',
					quantity: ''
				}

			};

		},

		watch: {

			'vModels.retailCost': {
				async handler(val) {

					let taxes = this.optionSets.taxes;
					let province = this.application.data.province;
					console.log('this.application.data.province', this.application.data.province);

					// let taxRate = this.optionSets.taxRate;
					let taxRate = 0

					taxes[province]['taxes'].forEach(item => {
						taxRate += Number(item.tax);
					});
					
					// taxes[province]['taxes'].forEach(item => {
						this.vModels.tax = (taxRate * this.vModels.retailCost).toFixed(2);
						this.vModels.total = (Number(this.vModels.tax) + Number(this.vModels.retailCost)).toFixed(2); // Number(Number(item.tax) * 100) * Number(this.vModels.retailCost);
					// });

				}
			},

			'vModels.assetTypes': {
				async handler(val) {

					if (Object.keys(val).length < 1) {
						// return
					}

					console.log('--------- vModels.assetTypes changed to: ', val);

					this.saveLoading(true);

					this.submitting = true;

					let result = await getAssetSubTypes({assetTypeId:val}).catch(async error => {

						// this.errorAlert = await this.showErrorAlert(error);

					});

					this.assetSubTypes = result;

					console.log(result);
					
					this.submitting = false;

					this.saveLoading(false);

				}
			},

			assetTypes: {
				handler(val) {

					console.log('val', val);

					// this.sync(val);

				},
				immediate: true
			},

			optionSets: {
				handler() {

					if (this.vModels) {

						Object.keys(this.vModels).forEach(key => {

							this.checkForOptions(key);

						});

					}

				}
			}

		},

		mounted() {

			this.saveLoading(true);

			this.getOptions(this.$route);
			this.retrieveAsset();

			this.saveLoading(false);

		},

		methods: {

			async getOptions() {

				this.saveLoading(true);

				let programId = 194; // pascalCase(this.$route.params.programId);
				let result = await getAssetTypes(programId).catch(async error => {

					this.errorAlert = await this.showErrorAlert(error);

				});
				console.log(result);
				this.assetTypes = result.assetTypes;
				this.assetSubTypes = result.assetSubTypes;

				this.saveLoading(false);

			},

			async retrieveAsset() {

				this.saveLoading(true);

				let assets = await getAssets(this.applicationId);

				let assetId = this.$route.params.assetId;
				
				let curAsset;

				Object.keys(assets).forEach(function (asset) {

					if (asset == assetId) {

						curAsset = assets[asset];

					}

				});

				console.log('curAsset', curAsset);

				this.vModels.total = curAsset.retailCost;
				this.vModels.assetId = curAsset.assetId;
				this.vModels.quantity = ''; // curAsset.quantity;
				this.vModels.description = curAsset.name;
				this.vModels.assetSubTypes = curAsset.assetSubTypeId;
				this.vModels.assetTypes = curAsset.assetTypeId;
				// this.vModels.installationDate = curAsset.installationDate;

				// let taxes = this.optionSets.taxes;
				let province = this.application.data.province;

				let taxMultiplier = this.optionSets.taxRate;

				// taxes[province]['taxes'].forEach(item => {
				// 	taxMultiplier += item.tax
				// });

				// console.log('price: ', Number(curAsset.retailCost / (1 + taxMultiplier)).toFixed(2));

				this.vModels.retailCost = Number(curAsset.retailCost / (1 + taxMultiplier)).toFixed(2); // / taxMultiplier;
				
				this.saveLoading(false);

				return curAsset;

			},

			async saveBtn() {

				this.saveLoading(true);

				await this.removeAlerts();

				let input = this.vModels;

				// make sure asset retailCost is less than approvedAmount
				let assets = await getAssets(this.applicationId);
				let total = 0;

				if (
					assets
					&& Object.keys(assets).length > 0
				) {

					Object.keys(assets).forEach((key, item) => {
						console.log(assets[key].retailCost);
						if (key != this.$route.params.assetId) {
							total += Number(assets[key].retailCost);
						}
					});

				}

				total += Number(input.total);
				console.log('total', total);

				if (
					this.application.data
					&& Number(total) > Number(this.application.data.amountApproved)
				) {

					await this.showErrorAlert(
						this.$i18n.t('assets.totalMore') + ' $' + this.application.data.amountApproved + '. ' +
						this.$i18n.t('assets.addMore') + ' $' + (Number(this.application.data.amountApproved) - (Number(total) - Number(input.total)) ).toFixed(2)
					);

					this.saveLoading(false);

					return false;

				}
				
				let payload = {
					assetName: input.quantity + ' ' + input.description,
					assetTypeId: input.assetTypes,
					assetSubTypeId: input.assetSubTypes,
					retailCost: input.total,
					assetId: input.assetId,
					id: this.applicationId
				};

				let result = await addAsset(payload).catch(async error => {

					let errorMsg = error.toString();

					if (error.response && error.response.data && error.response.data.errorMessage) {

						errorMsg = error.response.data.errorMessage;

					}

					if (errorMsg) {

						this.errorAlert = await this.showErrorAlert(errorMsg);

					}

				});

				console.log('result', result);

				if (result == "Success") {

					return this.$router.push({
						name: 'Assets',
						params: {
							applicationId: this.applicationId
						}
					});

				} else if (result.errorMessage) {
					this.errorAlert = await this.showErrorAlert(result.errorMessage);
				}

				this.saveLoading(false);

			}

		}

	};

</script>

<style lang="scss" scoped>

</style>
