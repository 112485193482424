import JoiValidator from '@incodeapps/joi-validator';

const Validator = new JoiValidator(Joi => ({

	'common': {

		id: Joi.string().allow(null).uuid(),
		edgeId: Joi.string().max(8).allow([null, '']),
		vendorId: Joi.number().allow([null, '']),
		amountApproved: Joi.money().allow('').positive().formatted()

	},

	'application-3.1quebecOnly': {

		grossIncome: Joi.money().required()/*.notZero()*/.positive().formatted(),
		totalHousingCosts: Joi.money().required()/*.notZero()*/.positive().formatted(),
		totalOtherMonthlyCosts: Joi.money().required()/*.notZero()*/.positive().formatted(),
		referral: Joi.string().allow(['', null]),
		referralOther: Joi.string().allow(['', null]),

	},

	'application-1': {

		creditReleaseObtained: Joi.string().required()
			.valid(['Yes']).error(error => {

				if (error[0].context && error[0].context.value === 'No') {

					if (error[0].context.locale === 'fr') {

						return 'Vous devez accepter les termes';

					} else {

						return 'You must accept the terms';

					}

				}

				return error;

			}),

		applicationType: Joi.string().required(),

		description: Joi.string().required(),

		amount: Joi.money().required().notZero().positive().formatted(),

		hasCoApplicant: Joi.string().allow(''),

	},

	'application-3.1': {

		firstName: Joi.string().required(),

		lastName: Joi.string().required(),

		sin: Joi.sin().allow(''),

		dob: Joi.dateOfBirth()
			.regex(/^\d{2}\/\d{2}\/\d{4}$/, 'mm/dd/yyyy')
			.possiblePast({min: 16, max: 120})
			.required(),

		primaryPhone: Joi.phone().regex().required(),

		mobilePhone: Joi.phone().allow('').regex(),

		email: Joi.string().required().email(),

		employer: Joi.string().required(),

		businessPhone: Joi.phone().regex().required(),

		grossIncome: Joi.money().allow('').positive().formatted(),

		totalHousingCosts: Joi.money().allow('').positive().formatted(),

		totalOtherMonthlyCosts: Joi.money().allow('').positive().formatted(),

		referral: Joi.string().valid(['other', 'porte-a-porte', 'telemarketing']).allow(''),

		referral: Joi.string().allow('')


	},

	'application-3.2': {

		country: Joi.string().required()
			.valid(['CA', 'US', 'CAN', 'USA']),

		address1: Joi.string().required(),

		address2: Joi.string().allow(''),

		city: Joi.string().required(),

		province: Joi.string().required().valid(['BC', 'AB', 'MB', 'SK', 'ON']),

		postal: Joi.postal().regex(/^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/).required(),

		titleOwner: Joi.string().required(),

		yearsAtCurHouse: Joi.number().allow(''),

		note: Joi.string().allow('')

	},

	'application-3.3': {

		curAddressLess: Joi.string().required(),

		prevAddress1: Joi.any().when('curAddressLess', {
			is: 'Yes',
			then: Joi.string().required(),
			otherwise: Joi.string().allow('')
		}),

		prevAddress2: Joi.any(),

		prevCity: Joi.any().when('curAddressLess', {
			is: 'Yes',
			then: Joi.string().required(),
			otherwise: Joi.string().allow('')
		}),

		prevProvince: Joi.any().when('curAddressLess', {
			is: 'Yes',
			then: Joi.string().required(),
			otherwise: Joi.string().allow('')
		}),

		prevPostal: Joi.any().when('curAddressLess', {
			is: 'Yes',
			then: Joi.postal().regex(/^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/).required(),
			otherwise: Joi.postal().allow('')
		})

	},

	'coBorrower-1': {

		creditReleaseObtained: Joi.string().required()
			.valid(['Yes']).error(error => {

				if (error[0].context && error[0].context.value === 'No') {

					if (error[0].context.locale === 'fr') {

						return 'Vous devez accepter les termes';

					} else {

						return 'You must accept the terms';

					}

				}

				return error;

			}),

		isImmediateFamily: Joi.string().required(),

		firstName: Joi.string().required(),

		lastName: Joi.string().required(),

		sin: Joi.sin().allow(''),

		coborrowerRelationship: Joi.string().required(),

		dob: Joi.dateOfBirth()
			.regex(/^\d{2}\/\d{2}\/\d{4}$/, 'mm/dd/yyyy')
			.possiblePast({min: 16, max: 120})
			.required(),

		primaryPhone: Joi.phone().regex().required(),

		mobilePhone: Joi.phone().allow('').regex(),

		email: Joi.string().allow('').email(),

		employer: Joi.string().required(),

		businessPhone: Joi.phone().regex().required()

	},

	'coBorrower-2': {

		country: Joi.string().required()
			.valid(['CA', 'US', 'CAN', 'USA']),

		address1: Joi.string().required(),

		address2: Joi.string().allow(''),

		city: Joi.string().required(),

		province: Joi.string().required(),

		postal: Joi.postal().regex(/^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/).required(),

		titleOwner: Joi.string().required(),

		yearsAtCurHouse: Joi.number().allow(''),

		coborrowerNote: Joi.string().allow('')

	}

}), ['en', 'fr']);

export default Validator;
